@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');

:root {
  --primary-color: #1f78d1;
  --secondary-color: #4fb3bf;
  --light-gray: #f5f5f5;
  --dark-gray: #333333;
  --text-color: #ffffff;
}

.App {
  text-align: center;
  font-family: 'Quicksand', Arial, sans-serif;
  background-color: var(--dark-gray);
  color: var(--text-color);
  overflow: hidden;
}

.App-header {
  background-color: var(--primary-color);
  padding: 20px;
  color: var(--text-color);
  position: relative;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header-name {
  margin: 0 10px;
  font-size: 2.5em;
  font-weight: 700;
}

.profile-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 20px;
}

.profile-background {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--primary-color) 50%, var(--dark-gray) 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Stronger shadow */
}

.App-profile {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.6);
}

.email-button-container {
  margin-top: 20px;
}

.email-button {
  background-color: var(--light-gray);
  color: var(--dark-gray);
  text-decoration: none;
  border-radius: 50%;
  border: 2px solid var(--secondary-color);
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px;
  transition: background-color 0.3s, color 0.3s;
}

.email-button:hover {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.App-section {
  padding: 0;
}

.panel {
  padding: 50px 20px;
  text-align: center;
  font-size: 1.5em;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 40px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.panel.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.white-panel {
  background-color: var(--light-gray);
  color: var(--dark-gray);
  clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
}

.black-panel {
  background-color: var(--dark-gray);
  color: var(--text-color);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
}

.panel p, .panel h2 {
  margin: 20px 0;
}

.panel a {
  color: var(--primary-color);
  text-decoration: none;
}

.panel a:hover {
  text-decoration: underline;
}

.ticker-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--dark-gray);
  color: var(--text-color);
  overflow: hidden;
}

.ticker {
  display: flex;
  width: 100%;
  animation: ticker 20s linear infinite;
  position: relative;
}

.ticker-item {
  flex: none;
  padding: 10px 20px;
  white-space: nowrap;
  font-size: 1.2em;
  color: var(--secondary-color);
  animation: bounce 2s infinite alternate;
  animation-delay: calc(var(--i) * 0.1s);
}

@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes bounce {
  to {
    transform: translateY(-10px);
  }
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: var(--light-gray);
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  border-radius: 8px;
  outline: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.close-button {
  background: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.Modal-cert {
  max-width: 100%;
  height: auto;
}
